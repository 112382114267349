import {getBaseUrl, getFullUrl} from "~/utils/url.js";

export function setProductSchemaItinerary(itinerary) {
  if (itinerary.bestPrice) {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(
            {
              '@context': 'http://schema.org/',
              '@type': 'Product',
              'name': itinerary.content.name,
              'brand': itinerary.ship.cruiseline.name,
              'image': getFullUrl(itinerary.slug),
              'description': itinerary.content.description,
              'offers': {
                '@type': 'AggregateOffer',
                'lowPrice': itinerary.bestPrice,
                'priceCurrency': 'EUR',
              },
            },
          ),
        },
      ],
    })
  }
}

export function setProductSchemaBestPrice(pageName, seo, bestPriceCruises = 100) {
  if (seo) {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(
            {
              '@context': 'http://schema.org/',
              '@type': 'Product',
              'name': pageName ? pageName : '',
              'description': seo.metaDescription ? seo.metaDescription : '',
              'image': seo.metaImage.data && seo.metaImage.data.attributes.url ? getFullUrl(seo.metaImage.data.attributes.url) : getBaseUrl() + '/logo.png',
              'offers': {
                '@type': 'Offer',
                'priceCurrency': 'EUR',
                'price': bestPriceCruises,
                'availability': 'https://schema.org/InStock ',
              },
            },
          ),
        },
      ],
    })
  }
}

export function setProductSchemaRating() {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(
          {
            '@context': 'http://schema.org/',
            '@type': 'Product',
            'name': 'Planète Croisière',
            'image': 'https://www.planete-croisiere.com/_ipx/w_232&f_webp/logo.png',
            'description': 'agence de voyages française </STRONG>basée à Roubaix et spécialisée dans la vente en ligne de <STRONG>croisières maritimes </STRONG>et <STRONG>fluviales</STRONG> depuis plus de 15 ans.',
            'aggregateRating': {
              '@type': 'AggregateRating',
              'ratingValue': '4.9',
              'bestRating': '5',
              'ratingCount': '403',
            },
          },
        ),
      },
    ],
  })
}
